.site__header {
    top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.menu__button {
    color: var(--text-color);
}

.menu__button:focus,
.menu__button:hover {
    color: var(--text-color);
    box-shadow: unset;
}

.menu__button svg {
    width: 2em;
    height: 2em;
    fill: var(--text-color-2);
    color: var(--text-color-2);
}

.nav_ac {
    padding: 13px 15px;
    margin: 0;
    border: unset;
    background: var(--primary-color);
    font-size: 1.25rem;
    font-family: Marcellus;
    color: var(--text-color-2);
    line-height: 2;
    height: 50px;
    font-weight: bold;
    z-index: 1000;
}

.nav_ac:hover {
    color: var(--text-color-2);
}

.br-top,
.br-bottom,
.br-right,
.br-left {
    position: fixed;
    z-index: 999999;
    background: var(--primary-color);
}

.br-top {
    top: 0;
    height: 10px;
    left: 0;
    width: 100%;
}

.br-bottom {
    bottom: 0;
    left: 0;
    height: 10px;
    width: 100%;
}

.br-right {
    width: 10px;
    right: 0;
    top: 0;
    height: 100%;
}

.br-left {
    width: 10px;
    left: 0;
    top: 0;
    height: 100%;
}

.cortina__wrapper-menu {
    position: relative;
    width: 100%;
    padding-top: 5em;
    padding-bottom: 3em;
    height: 100%;
    overflow-y: auto;
}

.site__navigation {
    height: 100%;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    visibility: hidden;
}

.menu__opend {
    visibility: visible !important;
}

.main__menu_ul,
.menu_right {
    opacity: 0;
    position: relative;
    transition: 0.5s;
    transition-delay: 0s;
    visibility: hidden;
    z-index: 100;
}

.menu_right {
    text-align: center;
}

.site__navigation.menu__opend .main__menu_ul,
.site__navigation.menu__opend .menu_right {
    opacity: 1;
    transition-delay: 0.6s;
    visibility: visible;
}

.site__navigation .main__menu_ul li {
    list-style: none;
    margin: 10px 0;
}

.site__navigation .main__menu_ul li a {
    color: var(--text-color);
    display: block;
    font-size: 2.5rem;
    text-decoration: none;
}

.bg__menu {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    will-change: transform;
    transform: translateY(-100%);
    transition: .5s ease all;
}

.menu__opend .bg__menu {
    transform: translateY(0);
}

.menu__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden auto;
}

.the_menu {
    padding-top: 20vh;
    padding-bottom: 20vh;
    padding-left: 0;
}

@media (min-width: 992px) {
    .menu__container {
        margin-left: 33.3333%;
    }
    .the_menu {
        padding-top: 10vh;
        padding-bottom: 10vh;
    }
    .logoIMG {
        width: 10%;
        height: 25%;
    }
}

.the_menu .menu_item>a {
    color: var(--text-color-2);
    line-height: 1;
    font-size: 2rem;
    display: inline-block;
    position: relative;
    transition: color 250ms cubic-bezier(0, 0, 0.58, 1) 0s;
    padding: 4px 0px;
    font-family: Marcellus;
}

@media (min-width: 768px) {
    .the_menu .menu_item>a {
        font-size: 4.8vw;
    }
    .logoIMG {
        width: 10%;
        height: 25%;
    }
}

.menu_footer {
    bottom: 0;
    font-family: Marcellus;
    font-size: 14px;
    background: var(--primary-color);
}

.menu_footer a {
    color: var(--text-color-2);
    margin-right: 10px;
}

.logoIMG {
    width: 4%;
    height: 5%;
    margin-top: -18px;
}

.bar-light-dark{
    margin-top:-3px;
}